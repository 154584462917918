import { ActionContext } from 'vuex'
import store, { RootState } from '.'
import { isConfigTruthy } from '@/addons/functions/configs'
import { handleCatchRequest } from '@/modules/endless-aisle/helpers/helpers'
import { catalogApi } from '@/addons/axios'
import { GetModelsAttributes } from '@/api'
import { GetModelsFiltersE } from '@/modules/endless-aisle/store'
import { NotificationsActions } from './notifications-store'
import I18n from '@/addons/i18n'

export enum Getters {
  GET_STOCK_FORM = 'get-stock-form',
  GET_STOCK_PAGE = 'get-stock-page',
  GET_STOCK_SORT = 'get-stock-sort',
  GET_LOADER = 'get-loader',
}

export enum ActionsNames {
  GET_MODELS = 'getModels',
}
export interface StockForm {
  filterType: string
  filterValue: string
  season?: string
  onlyPositive: boolean
}

export interface StockState {
  searchForm: StockForm
  page: number
  sort: { column: string; mode: string }
  isLoading: boolean
}

enum Getters {
  GET_STOCK_FORM = 'getStockForm',
  GET_STOCK_SORT = 'getStockSort',
  GET_STOCK_PAGE = 'getStockPage',
}
enum Action {
  GET_MODELS = 'getModels',
}
export const StockGetters = {
  GET_STOCK_FORM: `stock/${Getters.GET_STOCK_FORM}`,
  GET_STOCK_SORT: `stock/${Getters.GET_STOCK_SORT}`,
  GET_STOCK_PAGE: `stock/${Getters.GET_STOCK_PAGE}`,
  GET_LOADER: `stock/${Getters.GET_LOADER}`,
}

export const StockActions = {
  GET_MODELS: `stock/${Action.GET_MODELS}`,
}
const initState = (): StockState => ({
  searchForm: {
    filterType: '',
    filterValue: '',
    season: '',
    onlyPositive: false,
  },
  page: 1,
  sort: { column: '', mode: '' },
  isLoading: false,
})

export default {
  namespaced: true,
  state: initState,
  actions: {
    setFilterValue(
      context: ActionContext<StockState, RootState>,
      filterValue: string
    ): void {
      context.commit('updateFilterValue', filterValue)
    },
    resetState(context: ActionContext<StockState, RootState>): void {
      context.commit('resetState')
    },
    async [ActionsNames.GET_MODELS](
      context: ActionContext<StockState, RootState>,
      payload: GetModelsFiltersE
    ): Promise<Array<GetModelsAttributes | undefined> | undefined> {
      try {
        const m = await catalogApi.apiV1PoswebModelsGet(
          payload?.filterSku,
          payload?.filterGetRelated ?? '0',
          payload?.filterPkConsumer,
          payload?.filterBrand,
          payload?.filterCategoria,
          payload?.filterRighe || 20,
          payload?.filterPagina,
          payload?.filterModel,
          payload?.filterModelvariant,
          payload?.filterSizes,
          payload?.filterStock,
          payload?.filterName,
          payload?.filterEan,
          payload?.filterLikeMode,
          payload?.filterRfidStore,
          payload?.filterCodCategoria,
          payload?.filterCodSottocategoria,
          payload?.filterCollection,
          payload?.filterMainline,
          payload?.filterModelB2C,
          payload?.filterNewArrivals,
          payload?.filterOnSale,
          payload?.filterPrice,
          payload?.filterMinprice,
          payload?.filterMaxprice,
          payload?.filterYearSeason,
          payload?.filterStyleFilter,
          payload?.filterBodyLengthFilter,
          payload?.filterColorFilter,
          payload?.filterMaterialFilter,
          payload?.filterTypefilter,
          payload?.filterSleeveLengthFilter,
          payload?.filterOccasionFilter
        )

        if (!m.data.data || m.data.data.length === 0) {
          await store.dispatch(
            NotificationsActions.NOTIFY_ERROR,
            I18n.global.t('pos_stock.no_product_details_found')
          )
          return []
        }

        return m.data.data?.length ? m.data.data.map((d) => d.attributes) : []
      } catch (err: unknown) {
        await handleCatchRequest(err)
        return undefined
      }
    },
  },
  mutations: {
    setForm(state: StockState, form: StockForm): void {
      if (
        isConfigTruthy('ANNOSTAGIONE_FULLSEARCH_ENABLED') &&
        form.season === 'all'
      ) {
        delete form.season
      }
      state.searchForm = { ...form }
      state.page = 1
    },
    updateFilterValue(state: StockState, value: string) {
      state.searchForm.filterValue = value
      state.searchForm.filterType = 'sku'
    },
    clearSearchForm(state: StockState): void {
      state.searchForm = {
        filterType: '',
        filterValue: '',
        season: '',
        onlyPositive: false,
      }
      state.page = 1
    },
    setPage(state: StockState, page: number): void {
      state.page = page
    },
    resetPage(state: StockState): void {
      state.page = 1
    },
    setSort(state: StockState, sort: { column: string; mode: string }): void {
      state.sort = sort
    },
    clearSort(state: StockState): void {
      state.sort = { column: '', mode: '' }
    },
    openLoader(state: StockState): void {
      state.isLoading = true
    },
    closeLoader(state: StockState): void {
      state.isLoading = false
    },
    resetState(state: StockState): void {
      Object.assign(state, initState())
    },
  },
  getters: {
    [Getters.GET_STOCK_FORM](state: StockState): StockForm {
      return state.searchForm
    },
    [Getters.GET_STOCK_PAGE](state: StockState): number {
      return state.page
    },
    [Getters.GET_STOCK_SORT](state: StockState): {
      column: string
      mode: string
    } {
      return { ...state.sort }
    },
    [Getters.GET_LOADER](state: StockState): boolean {
      return state.isLoading
    },
  },
}
