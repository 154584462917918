<script lang="ts">
import { isNativeOrWebIos } from '@/addons/mobile'
import feHeader from '@/components/layout/header.vue'
import feSidebar from '@/components/layout/sidebar.vue'
import store from '@/store'
import { IonApp, IonContent, IonHeader } from '@ionic/vue'
import { defineComponent } from 'vue'

// import Log from '@/addons/logger'

export default defineComponent({
  name: 'FeApp',
  store: store,
  components: {
    IonApp,
    IonHeader,
    feHeader,
    feSidebar,
    IonContent,
  },
  mounted() {
    if (isNativeOrWebIos()) {
      const app = (this.$refs.app as typeof IonApp).$el as HTMLElement
      const header = (this.$refs.header as typeof IonHeader).$el as HTMLElement
      const content = (this.$refs.content as typeof IonContent)
        .$el as HTMLElement

      const headerHeight = header.getBoundingClientRect().height || 0

      app.style.height = `${window.innerHeight}px`
      content.style.height = `${window.innerHeight - headerHeight}px`
    }
  },
})
</script>

<template>
  <ion-app ref="app" data-app-root="">
    <ion-header ref="header" data-app-header="" class="ion-no-border">
      <fe-header></fe-header>
    </ion-header>
    <fe-sidebar class="sidebar"></fe-sidebar>
    <ion-content
      ref="content"
      data-app-content=""
      :force-overscroll="false"
      class="ion-main-content"
    >
      <router-view class="main-content"></router-view>
    </ion-content>
  </ion-app>
</template>

<style lang="scss" scoped>
@import '@/styles/_mixins.scss';

* {
  box-sizing: border-box;
}

ion-app {
  overflow-y: auto;
  display: grid;
  grid-template-columns: minmax(min-content, max-content) 1fr;
  grid-template-rows: minmax(min-content, max-content) 1fr;
}

ion-header {
  grid-row: 1;
  grid-column: 2;
}

.sidebar {
  grid-row: 1 / span 2;
  grid-column: 1;
}

ion-content {
  grid-row: 2;
  grid-column: 2;

  // For some reason, we have to do this in iOS 13 or
  // the page content would have no height.
  @include tablet-landscape {
    $header-height: 49px;
    height: calc(100vh - $header-height);
  }

  // This styles are used to hide main application content so that
  // the barcode scanner result can be shown to the user.
  &.has-barcode-scanner {
    --ion-background-color: transparent;

    & > *:first-of-type {
      display: none;
    }
  }
}
</style>
