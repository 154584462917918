<script lang="ts">
import { defineComponent } from 'vue'
import FeDrawerNav from '@/components/drawer/nav.vue'
import { checkLinkClick, parseHTML } from '@/addons/functions'
import { mapGetters } from 'vuex'
import { MenuGetters } from '@/store/menu-store'
import { getterNames as NotificationGetterNames } from './store/_getters'
import { notificationGroup } from './notifications'
import NotificationList from './NotificationList.vue'

interface TabItem extends Pick<notificationGroup, 'anchor'> {
  counter: number
  name: string
}

export default defineComponent({
  name: 'FeLeftDrawerNotifications',
  components: { NotificationList, FeDrawerNav },
  emits: ['close'],

  data() {
    return {
      activeTab: '',
      tabItems: [] as TabItem[],
    }
  },
  computed: {
    ...mapGetters({
      allMenuItems: MenuGetters.GET_ALL_ITEMS,
      notifications: NotificationGetterNames.ALL_NOTIFICATIONS,
    }),
    /**
     * Retrieves a flattened array of all menu items, including those in submenus.
     * @returns {Object[]} An array of all menu items.
     */
    menuItems() {
      // console.log(this.allMenuItems)
      return this.allMenuItems.flatMap((i) =>
        i.attributes?.submenu?.flatMap((j) =>
          j.attributes?.submenu?.flatMap((k) => k)
        )
      )
    },
    /**
     * Filters the notification groups to only include the group with the active tab anchor.
     * @returns {notificationGroup[]} An array of notification group data for the active tab.
     */
    computedNotifications() {
      return this.notifications.filter(
        (group) => group.anchor === this.activeTab
      )
    },
  },
  beforeMount() {
    this.get_tabItems()
  },

  methods: {
    parseHTML,
    changeTab(anchor: string) {
      this.activeTab = anchor
    },
    /**
     * Navigates to the menu item with the specified ID and closes the notifications drawer.
     *
     * @param idMenu - The ID of the menu item to navigate to.
     */
    async goTo(idMenu: string) {
      await checkLinkClick(
        this.menuItems.find((m) => m.id === idMenu) || {},
        undefined
      )
      this.$emit('close')
    },
    /**
     * Retrieves the tab items for the notifications drawer,
     * including the notification groups and their corresponding anchors and counters.
     * This method is called in the `beforeMount` hook to initialize the tab items.
     */
    get_tabItems() {
      this.tabItems = this.notifications.map((group) => ({
        name: group.label,
        anchor: group.anchor,
        counter: group.counter,
      }))

      if (this.tabItems.length > 0) {
        this.activeTab = this.tabItems[0].anchor
      }
    },
  },
})
</script>

<template>
  <div class="notifications">
    <fe-drawer-nav
      mode="tab"
      class="notifications__nav"
      :nav-items="tabItems"
      @change-tab="changeTab"
    ></fe-drawer-nav>

    <NotificationList
      v-for="item in computedNotifications"
      :key="item.anchor"
      :notifications="item.notifications"
      @go-to="goTo"
    />
  </div>
</template>

<style lang="scss">
@import '@/styles/constants';

.notifications {
  padding: 0 2rem;
  &__nav {
    margin-top: 0.9rem;
    background: $color-white !important;

    a {
      width: 100%;
      text-align: center;
    }
  }

  &__list {
    margin: 2rem 1.7rem 0 2.3rem;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 0.6rem;
      box-shadow: 0 0.3rem 1.6rem rgba(0, 0, 0, 0.1);
      margin-bottom: 1rem;
      padding-left: 1rem;

      &--message {
        font-size: 1.8rem;
        margin: 1rem 0;
      }

      .fe-icon {
        font-size: 1.6rem;
        width: unset !important;
        height: unset !important;
      }
    }
  }
}
</style>
