<script lang="ts">
import { defineComponent } from 'vue'
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
} from '@ionic/vue'
import FeButton from '@/components/global/button.vue'
import FeIcon from '@/components/global/icon.vue'
import FeExternalLinks from '@/components/sidebar/left-drawer-externllink.vue'
import FeLeftDrawerNotifications from '@/components/notifications-list/notifications.vue'
export default defineComponent({
  name: 'FeDrawerLeft',
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonContent,
    FeButton,
    FeIcon,
    FeExternalLinks,
    FeLeftDrawerNotifications,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: () => 'menu-left',
    },
    title: {
      type: String,
      default: () => '',
    },
    component: {
      type: String,
      default: () => '',
    },
  },
  emits: ['update:modelValue'],
  methods: {
    onClose(): void {
      this.$emit('update:modelValue', false)
    },
  },
})
</script>

<template>
  <div v-if="modelValue" class="fe-drawer-layer" @click="onClose"></div>
  <!-- do not import because importing causes the ion-menu open method to not work -->
  <transition name="drawer-left-" @after-leave="onClose">
    <div v-if="modelValue" class="fe-drawer-left__container">
      <ion-header>
        <ion-toolbar>
          <ion-title>{{ $t(title) }}</ion-title>
          <ion-buttons slot="end">
            <fe-button color="icon" @click="onClose">
              <fe-icon class="header__icon" icon="close" />
            </fe-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">
        <component :is="component" @close="onClose"></component>
      </ion-content>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';
.fe-drawer {
  &-layer {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    top: 4.9rem;
    left: 6.3rem;
    @include z-index('modal', -1);
  }
  &-left {
    &__container {
      top: 49px;
      left: 63px;
      box-shadow: -0.1rem 0.5rem 1.6rem 0 #aaaaaa;
      transition: all 0.3s ease-in-out;
      position: fixed;
      width: 57%;
      bottom: 0;
      @include z-index('modal');
    }
  }
}
.drawer-left {
  &--enter-from,
  &--leave-to {
    opacity: 0;

    &.fe-drawer-left__container {
      transform: translateX(-100%);
    }
  }

  &--leave-from,
  &--enter-to {
    opacity: 1;

    &.fe-drawer-left__container {
      transform: translateX(0%);
    }
  }
}
ion-header {
  box-shadow: 0 0.2rem 3.2rem rgba(0, 0, 0, 0.1);
}
</style>
