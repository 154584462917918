import { initModules } from '@/modules/initModules'

import feApp from '@/app.vue'
import router, { isLoginPage } from '@/router'
import store from '@/store'
import { ConfigActions, ConfigGetters } from '@/store/configs-store'
import { StatusBar, Style } from '@capacitor/status-bar'
import { createApp } from 'vue'
import Toast from 'vue-toastification'
import Log from '@/addons/logger'
import { AbortControllerHelper, prepareApiHelpers } from '@/addons/axios'
import i18n, {
  loadRouteMessages,
  loadRouteMessagesFromLocalJson,
} from '@/addons/i18n'
import {
  KeyboardHelper,
  LiveUpdatesHelper,
  isNativeIos,
  isNativeMobileApp,
} from '@/addons/mobile'
import { handlePersistance } from '@/addons/persistence'
import { App } from '@capacitor/app'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'
import { IonicVue } from '@ionic/vue'

import '@/theme/theme.scss'
import '@ionic/vue/css/core.css'
import 'vue-toastification/dist/index.css'

import feAccordion from '@/components/global/accordion.vue'
import feButton from '@/components/global/button.vue'
import feIconComponent from '@/components/global/icon.vue'
import feSelectOptionComponent from '@/components/global/select-option.vue'
import feSelectComponent from '@/components/global/select.vue'
import feToggleComponent from '@/components/global/toggle.vue'

import baseModal from '@/components/global/base-modal.vue'

import { SoundCueHelper } from '@/addons/functions/sales'
import { getSid, parseScssVariabiles } from './addons/functions'

import { PageContexts } from '@/addons/enums'
import LoaderController from '@/components/loader/LoaderController'
import mixins from '@/styles/_mixins.scss'
import { ConnectivityChecker } from './addons/connectivity'
import { FeLogger } from './addons/monitoring'
import {
  LIVE_UPDATE_CHANNEL_MAPPING,
  LIVE_UPDATE_DEFAULT_CHANNEL,
} from './configs'
import { AuthActions, AuthGetters } from './store/auth'

export const SCSS_MIXINS = parseScssVariabiles(mixins)

export const app = createApp(feApp)
  .use(IonicVue)
  .use(i18n)
  .use(router)
  .use(store)
  .use(Toast)
store.subscribe((mutation, state) => {
  handlePersistance(mutation, state)
})

app.component('FeIcon', feIconComponent)
app.component('FeSelect', feSelectComponent)
app.component('FeSelectOption', feSelectOptionComponent)
app.component('FeToggle', feToggleComponent)
app.component('FeAccordion', feAccordion)
app.component('FeButton', feButton)
// This is experimental: use modalController
app.component('BaseModal', baseModal)

AbortControllerHelper.init()
FeLogger.init()

prepareApiHelpers().then(async () => {
  await router.isReady()

  Log.info('Init app modules')
  await initModules() // XXX: moved from '/src/router/guards.ts' - line 110

  let channel = LIVE_UPDATE_DEFAULT_CHANNEL

  try {
    // If we do not already have store setup data, we have to invoke the API now.
    if (!store.getters[ConfigGetters.GET_STORE_SETUP]) {
      await store.dispatch(ConfigActions.FETCH_STORE_SETUP)
    }

    // Get the reported deployment environment. This will allow us to use the correct live update channel.
    const deployEnv = store.getters[ConfigGetters.DEPLOY_ENV]
    channel = LIVE_UPDATE_CHANNEL_MAPPING[deployEnv]
  } catch (err) {
    FeLogger.error(err)
  }

  await LiveUpdatesHelper.init({
    channel: channel,
  })

  // If we are actually running on an iOS device, change color status bar so that the text is darker.
  if (isNativeIos()) {
    // Yes, this is somewhat counter intuitive, but this is indeed correct.
    StatusBar.setStyle({ style: Style.Light })
  }

  app.mount('#app')
})

const loaderContainer = document.createElement('div')
loaderContainer.setAttribute('id', 'pageloader-container')
document.body.appendChild(loaderContainer)
const loader = document.createElement('div')
loader.setAttribute('id', 'pageloader')
loaderContainer.appendChild(loader)

router.beforeEach(async (to, _from, next) => {
  if (!isLoginPage(_from)) {
    const pageContext = to.meta.context ? to.meta.context : PageContexts.GENERIC
    LoaderController.show({
      section: pageContext,
    })
  }

  // If we got a SID cookie, but we do not have that value in the auth store, we have to set it.
  // This way, subsequent requests work as expected. If the SID turns out to be invalid (for whatever reason),
  // user will be redirected to the login page since HTTP requests would fail with a 401 Unauthorized.
  if (!store.getters[AuthGetters.GET_SID]) {
    const sid = await getSid()
    if (sid) {
      await store.dispatch(AuthActions.SET_SID, sid, { root: true })
    }
  }

  const hasSid = !!store.getters[AuthGetters.GET_SID]

  // If user tries to load something that is not the login page, we have to perform some checks.
  if (!isLoginPage(to) && !hasSid) {
    // If we have no sid, user is definitely unauthenticated: redirect him/her to the login page.
    await store.dispatch(AuthActions.GOTOLOGIN)

    // Since we found out that we cannot proceed, we have to signal that so that we do not make
    // additional requests that will lead to a redirect loop (this especially true in Safari).
    return false
  }

  if (isLoginPage(to)) {
    await loadRouteMessagesFromLocalJson(
      i18n,
      to.meta.localesModules as string[]
    )
  } else {
    await loadRouteMessages(i18n, to.meta.localesModules as string[])
  }

  return next()
})

router.afterEach(() => {
  LoaderController.hide()
})

if (isNativeMobileApp()) {
  Keyboard.addListener('keyboardDidShow', (info: KeyboardInfo) => {
    KeyboardHelper.keyboardDidShow(info)
  })

  // If user hides the keyboard when an input field is in focus, we can blur that field.
  // In this way, user can remove focus from an input field either by tapping away from it or by hiding the keyboard.
  Keyboard.addListener('keyboardWillHide', () => {
    KeyboardHelper.keyboardDidHide()
  })

  App.addListener('appStateChange', (state) => {
    // If application becomes active, we can restart the connectivity checks
    if (state.isActive) {
      ConnectivityChecker.getInstance().restart()
    } else {
      // Since we do not want users to be able to skim through the audio file, nor we want to give him/her the ability to
      // pause it and replay it, we set an empty src property so that the native Media controls are removed from the
      // lock screen (once the application has lost focus).
      SoundCueHelper.stop()
    }
  })
}
