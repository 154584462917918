<script setup lang="ts">
import { defineProps, defineEmits, onMounted, Ref, ref, useSlots } from 'vue'

interface ModalProps {
  name: string
  teleportTo?: string
  title?: string
  closeOnBackgroundClick?: boolean
  closeOnEscClick?: boolean
  visible?: boolean
  noneVisible?: boolean
}

const props = defineProps<ModalProps>()

const emits = defineEmits(['update:visible'])

const closeModal = () => {
  emits('update:visible', false)
}

const handleBackgroundClick = () => {
  if (props.closeOnBackgroundClick) {
    closeModal()
  }
}
const handleEscKey = (event: KeyboardEvent) => {
  if (props.closeOnEscClick && event.key === 'Escape') {
    closeModal()
  }
}

const modalContent: Ref<HTMLElement | null> = ref(null)
onMounted(() => {
  if (modalContent.value) {
    modalContent.value.focus()
  }
})

const slots = useSlots()
const hasHeaderSlot = !!slots.header
const hasFooterSlot = !!slots.footer
</script>

<template>
  <Teleport :to="props.teleportTo" :disabled="!props.teleportTo">
    <div
      v-if="props.visible"
      ref="modalWrapper"
      class="base-modal-wrapper"
      data-test="'modal-close-outside'"
      :data-test-id="props.name"
      @click.self="handleBackgroundClick"
      @keyup.esc="handleEscKey"
    >
      <div ref="modalContent" class="modal-content" tabindex="0">
        <div v-if="hasHeaderSlot" class="header">
          <slot name="header">
            <p class="header__title" :data-test="props.title">
              {{ props.title }}
            </p>

            <fe-button
              color="icon"
              class="header__close"
              data-test="'modal-close-button'"
              @click="closeModal"
            >
              <fe-icon class="header__icon" icon="close" />
            </fe-button>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div v-if="hasFooterSlot" class="modal-footer">
          <slot name="footer"></slot>
        </div>
        <slot></slot>
      </div>
    </div>
  </Teleport>
</template>

<style lang="scss">
@import '@/styles/_constants.scss';
@import '@/styles/_mixins.scss';

.d-none {
  display: none !important;
}
.base-modal-wrapper {
  @include z-index('modal', 2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba($color-base, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-content {
    transition: all 0.3s ease-in-out;
    box-shadow: -1.6rem 1.8rem 2.4rem rgba($color-black, 0.05);

    .header {
      background: $color-selected-light;
      color: $color-white;
      padding: 0 2rem;
      box-sizing: border-box;
      grid-row: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 0.8rem 0.8rem 0 0;
      // min-height: 4rem;

      &__title {
        text-transform: uppercase;
        margin: 0;
        font-weight: 600;
        font-size: 1.6rem;
      }

      &__icon ion-icon {
        fill: white !important;
      }
    }
  }

  .modal-title {
  }

  .modal-body {
  }

  .modal-footer {
  }
}
</style>
