import Log from '@/addons/logger'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { RootState } from '@/store'
import { ActionNames } from '@/components/notifications-list/store/_actions'

export type notificationGroup = {
  weight: number
  label: string
  anchor: string
  retrieveNotifications: () => Promise<Notification[]>
  enabled: () => boolean
}

export type Notification = {
  messaggio: string
  action: boolean // TODO: check the current implementation on notification.vue
  id_menu?: string
}

export type notificationGroupData = {
  label: string
  anchor: string
  counter: number
  notifications: Notification[]
}

/**  Notification tabs registry:
 * It contains all the notification tabs that should be visible
 * on the left side of the application, such as:
 *
 *  * promemoria
 *  * Report Click And Collect (CEC)
 *  * Prmotions
 *
 * @private
 */
const _registry: notificationGroup[] = []

/**
 * Adds a new notification group to the registry or updates an existing one.
 *
 * This function takes a weight value and a function to retrieve the notification group data.
 * It checks if a group with the same anchor already exists in the registry. If so, it updates the
 * existing group with the new weight and retrieve function. Otherwise, it adds a new group to
 * the registry.
 *
 * @param {notificationGroup} data - the notification group
 * @returns {void}
 */
export const addNotificationGroup = (data: notificationGroup): void => {
  const { anchor } = data

  const existingIndex = _registry.findIndex((group) => group.anchor === anchor)

  if (existingIndex === -1) {
    _registry.push(data)
  }
  Log.info(`added notification group: ${anchor}`)
}

/**
 * Returns the list of notification group data sorted by weight.
 *
 * This function iterates through the _registry array, which contains all the registered notification groups.
 * It sorts the groups by their weight property, then calls the get() function on each group to retrieve its data.
 * The resulting array of notificationGroupData objects is returned.
 *
 * @returns {notificationGroup[]} - An array of notification group data, sorted by weight.
 */
export const getNotificationGroups = (): notificationGroup[] => {
  const data: notificationGroup[] = []
  Object.keys(_registry)
    .filter((g) => _registry[g].enabled())
    .sort((a, b) => _registry[a].weight - _registry[b].weight)
    .forEach((k) => {
      data.push(_registry[k])
    })
  return data
}

/**
 * Provides access to notifications and functions to manage them.
 * This function uses the Composition API to interact with the Vuex store.
 *
 * @returns {Object} An object containing:
 * - `setNotificationGroupsData`: An array of all notifications from the Vuex store.
 * - `getNotificationGroupsData`: A function to add a new notification to the store.
 */
export function useNotifications(): object {
  const store = useStore<RootState>()

  // retrieve data from API and set value to store
  const setNotificationGroupsData = async () => {
    await store.dispatch(ActionNames.RETRIEVE_NOTIFICATIONS)
  }

  // retrieve data from store

  /**
   * Computed property to access the notifications getter from the Vuex store.
   *
   * @returns {ComputedRef<notificationGroupData[]>} Reactive reference to the notifications array.
   */
  const notifications = computed(
    (): notificationGroupData[] => store.getters.allNotifications
  )

  return {
    notifications,
    setNotificationGroupsData,
  }
}

/** Example of usage:
 *  ...init module ...
 *
 * const retrieveNotifcations = () => {
 *    check if the plugin is enabled
 *    retrieve the notification data
 *    format the data following the notificationGroupData type
 * }
 *
 * from @core/notification import addNotificationGroup
 * addNotificationGroup({...})
 *
 */
