import debug from 'debug'
import { APP_NAME } from '@/configs'

export type LogLevel = 'info' | 'warn' | 'error'
export type LogMessage = string
export type LogContext = { [key: string]: string }
export type LogTransport = (
  level: LogLevel,
  message: LogMessage,
  context?: LogContext
) => void

// FeLogger.init()
const ERROR_COLOURS: Record<LogLevel, string> = {
  info: 'dodgerblue',
  warn: 'orange',
  error: 'red',
}

const browserConsoleTransport: LogTransport = (level, message, context) => {
  // Set the prefix which will cause debug to enable the message
  const namespace = `${APP_NAME}:${level}`
  const logger = debug(namespace)

  if (logger.enabled) {
    // Set the colour of the message based on the level
    logger.color = ERROR_COLOURS[level]
    logger(message, JSON.stringify(context) || '')
  }
}

const _LOG_TRANSPORT_REGISTRY: LogTransport[] = [browserConsoleTransport]

export const addLogTransport = (logTransport: LogTransport) => {
  _LOG_TRANSPORT_REGISTRY.push(logTransport)
}
/** Generate a debug message */
const generateMessage = (
  level: LogLevel,
  message: LogMessage,
  context?: LogContext
) => {
  // enable extra transports
  _LOG_TRANSPORT_REGISTRY.forEach((transport) => {
    transport(level, message, context)
  })
}

/**
 *
 * How to use:
 *  import Log from '@/addons/logger'
 *  Log.info('my amazing Message');
 *
 * It will print "my amazing Message" on browser console in a brillant blue
 *
 * To enable logging it is necessary to add an entry 'debug'
 * on browser's localStorage with this value:
 *
 *  [APP_NAME]:<level|*>
 *
 * For intstance:
 *
 * The value: `abstrid:*` will show all the levels of logs
 * The value: `abstrid:error` will show only the log with error level
 *
 * Further info: https://www.npmjs.com/package/debug
 */
const logger = {
  info(message: LogMessage, context?: LogContext) {
    return generateMessage('info', message, context)
  },
  warn(message: LogMessage, context?: LogContext) {
    return generateMessage('warn', message, context)
  },
  error(message: LogMessage, context?: LogContext) {
    return generateMessage('error', message, context)
  },
}

export default logger
