import { Module } from 'vuex'
import { State, state } from './_state'
import { getters } from './_getters'
import { mutations } from './_mutations'
import { actions } from './_actions'
import { RootState } from '@/store'

const notificationsListModule: Module<State, RootState> = {
  state,
  mutations,
  getters,
  actions,
}

export default notificationsListModule
