<script setup lang="ts">
import { defineProps, defineEmits } from 'vue'
import FeButton from '@/components/global/button.vue'
import FeIcon from '@/components/global/icon.vue'
import { parseHTML } from '@/addons/functions'

interface Notification {
  messaggio: string
  action?: boolean
  id_menu?: string
}

const props = defineProps<{
  notifications: Notification[]
}>()

const emit = defineEmits<{
  (e: 'goTo', idMenu: string): void
}>()

const handleGoTo = (idMenu: string) => {
  emit('goTo', idMenu)
}
</script>

<template>
  <div class="notifications__list">
    <div
      v-for="notification in props.notifications"
      :key="notification.messaggio"
      class="notifications__list__item"
    >
      <span class="notifications__list__item--message">{{
        parseHTML(notification.messaggio)
      }}</span>
      <fe-button
        v-if="notification.action"
        color="icon"
        @click="() => handleGoTo(notification.id_menu!)"
      >
        <fe-icon icon="go"></fe-icon>
      </fe-button>
    </div>
  </div>
</template>
