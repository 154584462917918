import axios, { AxiosResponse } from 'axios'
import store from '@/store'
import { CDN_URL } from '@/configs'
import { isArray, merge } from 'lodash'
import { ConfigActions } from '@/store/configs-store'
import { CdnManifest, LoyaltyParams } from '@/interfaces/cdnManifest'

// Do Note: baseURL should be empty string
const apiClient = axios.create({
  baseURL: '',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: false,
})

/**
 * Retrieves the base path for the CDN, which includes the CDN URL and the brand from the Vuex store.
 * The resulting base path has any trailing slash removed.
 *
 * @returns {string} The base path for the CDN.
 */
const getCDNBasePath = (): string => {
  let cdnUrl = store.state.configs?.setup?.cdn_url || CDN_URL
  const brand = store.state.configs?.setup?.stores[0].store_sign

  // Strip the slash at the end if present
  if (cdnUrl.endsWith('/')) {
    cdnUrl = cdnUrl.replace(/\/$/g, '')
  }
  return `${cdnUrl}/${brand}`
}

/**
 * Retrieves the CDN manifest data, either from the Vuex store or by fetching it from the CDN.
 *
 * If the `storeCdnManifest` data is already present in the Vuex store, it will be returned.
 * Otherwise, it will fetch the `manifest.json` file from the CDN base path and store the data in the Vuex store.
 *
 * @returns {Promise<CdnManifest>} The CDN manifest data.
 */
const retrieveCDNManifest = async (): Promise<CdnManifest> => {
  if (Object.entries(store.state.configs?.storeCdnManifest).length !== 0) {
    return store.state.configs?.storeCdnManifest
  }
  const path = getCDNBasePath()
  const storeCdnManifest: AxiosResponse<CdnManifest> = await apiClient.get(
    `${path}/manifest.json`
  )
  const data = storeCdnManifest.data

  // if loyalty data set is present
  // we merge the default data to every program subset
  if (data.loyalty) {
    const storeCdnManifestloyalty = data.loyalty

    Object.keys(storeCdnManifestloyalty)
      .filter((el) => el !== 'default')
      .forEach((k) => {
        storeCdnManifestloyalty[k] = merge(
          storeCdnManifestloyalty.default,
          storeCdnManifestloyalty[k]
        )
      })
    data.loyalty = storeCdnManifestloyalty
  }

  store.dispatch(ConfigActions.GET_STORE_CDN_MANIFEST, data)

  return data
}

/**
 * Retrieves the loyalty data for a specific loyalty program.
 *
 * If the loyalty data for the specified program is available in the CDN manifest,
 * it will be returned. Otherwise, the default loyalty data will be returned.
 *
 * The function also transforms any image or document paths to their full CDN paths.
 *
 * @param {string} program - The name of the loyalty program to retrieve data for.
 * @returns {Promise<LoyaltyParams>} The loyalty data for the specified program.
 */
export async function getLoyaltyData(program: string): Promise<LoyaltyParams> {
  const manifestData: CdnManifest = await retrieveCDNManifest()
  let loyaltyData: LoyaltyParams
  if (Object.keys(manifestData.loyalty).indexOf(program) !== -1) {
    loyaltyData = manifestData.loyalty[program]
  }
  loyaltyData = manifestData.loyalty.default

  // transform values to path
  if (!loyaltyData.image.startsWith('http')) {
    loyaltyData.image = getCDNPathValue(loyaltyData.image) as string
  }
  const docs = {}
  Object.keys(loyaltyData.doc).forEach((lang) => {
    // il problema è che qui l'oggetto docs viene inizializzato anche se poi è vuoto
    // quindi il controllo a riga 104 non funziona
    docs[lang] = {}
    Object.keys(loyaltyData.doc[lang]).forEach((name) => {
      if (!loyaltyData.doc[lang][name].startsWith('http')) {
        docs[lang][name] = getCDNPathValue(
          loyaltyData.doc[lang][name]
        ) as string
      }
    })
  })

  // if (Object.keys(docs).length > 0) { questo controllo non andava bene
  if (Object.keys(docs[Object.keys(docs)[0]]).length > 0) {
    loyaltyData.doc = docs
  }

  return loyaltyData
}

/**
 * Retrieves a value from the CDN based on the provided name.
 *
 * The name can be a single key or a dotted notation to access nested properties in the CDN manifest data.
 * The resulting image URLs will include the base path for the CDN.
 *
 * @param {string} name - The name or dotted notation to access the image(s) in the CDN manifest data.
 * @returns {Promise<any>} The value corresponding to the provided name.
 */
export const getValueFromCDN = async (name: string): Promise<any> => {
  const manifestData: CdnManifest = await retrieveCDNManifest()

  let keys = name.split('.')
  return keys.reduce((a, c) => a[c], manifestData) as any
}

/**
 * Constructs the full CDN path for a given image path.
 *
 * This function takes a string or an array of strings representing image paths,
 * and prepends the base CDN path to each one. If the input path starts with a
 * leading slash, the slash is removed before prepending the base path.
 *
 * @param {string | string[]} data - The image path(s) to construct the full CDN path for.
 * @returns {string | string[]} The full CDN path(s) for the provided image path(s).
 */
export const getCDNPathValue = (data: string): string | string[] => {
  const basePath: string = getCDNBasePath()
  // Strip initial slash from image path
  const stripSlash = (el: string) => {
    if (el.startsWith('/')) {
      return el.replace(/^\//g, '')
    }
    return el
  }

  if (isArray(data)) {
    return data.map((el: string) => `${basePath}/${stripSlash(el)}`)
  }

  return `${basePath}/${stripSlash(data)}`
}

/**
 * Retrieves a path from the CDN based on the provided name.
 * It uses `getValueFromCDN` to retrieve the value of given node.
 *
 * @param {string} name - The name or dotted notation to access the image(s) in the CDN manifest data.
 * @returns {Promise<string | string[]>} The image URL or an array of image URLs.
 *
 * es.:
 * const data = {'a': '/folder/file.png' }
 * getPathFromCDN('a') // returns 'https://<cdn_url>/<brand>/folder/file.png
 */
export const getPathFromCDN = async (
  name: string
): Promise<string | string[]> => {
  let data = await getValueFromCDN(name)

  if (!data) {
    return ''
  }

  return getCDNPathValue(data)
}

export default {
  getLoginImages(): Promise<string[]> {
    return getPathFromCDN('login') as Promise<string[]>
  },
  // getFileFromCDN, ES: policy loyalty
}
