import { RouteLocationNormalized } from 'vue-router'
import router from '.'

export enum Routes {
  LOGIN = '/login',

  DASHBOARD = '/dashboard',
  DASHBOARD_SUBMENU = ':submenu',

  SALES = '/sales',
  RECAP = '/recap',

  STOCK = '/stock',

  CASH_CHECK = '/cash-check',

  DEPOSITS = '/deposits',
  DEPOSITS_LIST = '/deposits/list',

  PETTYCASH = '/petty-cash',
  PETTY_CASH_LIST = '/petty-cash/list',
  PETTYCASHV2 = '/petty-cash-v2',
  SALES_LIST = '/sales-list',
  SALES_DETAIL = '/sales-detail/:id',

  PRICE_UPDATE = '/price-update',
  GIFT_CARD_BALANCE = '/gift-card-balance',

  CASH_CLOSING = '/cash-closing',

  REFUND_B2E = '/refund-b2e',
  SUSPENDED_CREDIT = '/suspended-credit',
  ALL_SUSPENDED_CREDITS = '/suspended-credit/all',
  SUSPENDED_CREDIT_PERFORMED_PAYMENTS = '/suspended-credit/payments',
  LAYBY = '/layby',
  USERS = '/users',
  RETURNS = '/returns',
  RECAP_RETURNS = '/returns/recap-returns',
  BANKS = '/banks',
  TOOLS = '/tools',

  /**
   * A special route used to show an iframe pointing to pages exposed by the previous UI.
   */
  PREVIOUS_UI_PAGES = '/previous',
  ENDLESS_AISLE = '/endless-aisle',
}

export enum RoutesName {
  SALES_DETAIL = 'salesDetail',
  DASHBOARD_SUBMENU = 'dashboardSubmenu',
}

/**
 * Checks if the current route matches the specified route path.
 * @param routePath - The route path to check against.
 * @param route - The route to check. Defaults to the current route.
 * @returns `true` if the route path matches the specified route, `false` otherwise.
 */
export function isRoute(
  routePath: Routes,
  route: RouteLocationNormalized = router.currentRoute.value
) {
  return route.path === routePath
}

/**
 * An helper functions that tells if user is viewing the login page.
 * @param route An optional route on which we should perform the check
 */
export function isLoginPage(
  route: RouteLocationNormalized = router.currentRoute.value
) {
  return isRoute(Routes.LOGIN, route)
}
